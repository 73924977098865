<template>
    <div class="page-main">
        <div class="main-banner">
            <img src="../../assets/image/main-page/client_banner.jpg" alt="">
            <div class="banner-text">
                <p class="tit">入驻商家</p>
                <p class="tit-en">Settled businesses</p>
            </div>
        </div>
        <client-module></client-module>
    </div>
</template>

<script>
    import ClientModule from '../page-module/client-module'
    export default {
        name: "client-page",
        components: {
            ClientModule
        },
    }
</script>

<style scoped lang="scss">
    @import "../../assets/css/main-module";
</style>
