<template>
    <div class="module-single">
        <div class="module-main">
            <div class="module-tit-group">
                <p class="tit">入驻商家</p>
                <p class="tit-en">Settled businesses</p>
                <span class="line"></span>
            </div>
            <div class="client-group">
                <ul class="client-lists">
                    <li>
                        <img src="../../assets/image/main-page/client_img1.png" alt="">
                        <div class="text-group">
                            <div class="icon">
                                <img src="../../assets/image/main-page/client_icon.png" alt="">
                            </div>
                            <p class="client-tit">浙江物产化工集团宁波有限公司</p>
                        </div>
                    </li>
                    <li>
                        <img src="../../assets/image/main-page/client_img2.png" alt="">
                        <div class="text-group">
                            <div class="icon">
                                <img src="../../assets/image/main-page/client_icon.png" alt="">
                            </div>
                            <p class="client-tit">东莞市同舟化工有限公司</p>
                        </div>
                    </li>
                    <li>
                        <img src="../../assets/image/main-page/client_img3.png" alt="">
                        <div class="text-group">
                            <div class="icon">
                                <img src="../../assets/image/main-page/client_icon.png" alt="">
                            </div>
                            <p class="client-tit">中蓝国际化工有限公司</p>
                        </div>
                    </li>
                    <li>
                        <img src="../../assets/image/main-page/client_img4.png" alt="">
                        <div class="text-group">
                            <div class="icon">
                                <img src="../../assets/image/main-page/client_icon.png" alt="">
                            </div>
                            <p class="client-tit">广西田东锦亿科技有限公司</p>
                        </div>
                    </li>
                    <li>
                        <img src="../../assets/image/main-page/client_img5.png" alt="">
                        <div class="text-group">
                            <div class="icon">
                                <img src="../../assets/image/main-page/client_icon.png" alt="">
                            </div>
                            <p class="client-tit">青岛海力加化学新材料有限公司</p>
                        </div>
                    </li>
                    <li>
                        <img src="../../assets/image/main-page/client_img6.png" alt="">
                        <div class="text-group">
                            <div class="icon">
                                <img src="../../assets/image/main-page/client_icon.png" alt="">
                            </div>
                            <p class="client-tit">德州德田化工有限公司</p>
                        </div>
                    </li>
                </ul>
                <button class="look-more-btn" v-if="currentIndexNum === '0'" @click="toLookMore">查看更多</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "client",
        computed: {
            currentIndexNum () {
                return this.$store.state.pageIndex
            }
        },
        methods: {
            toLookMore(){
                this.$router.push("client");
                this.$store.commit("updatePage", "3");
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/css/base";
    @import "../../assets/css/main-module";
</style>
